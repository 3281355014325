import {Toast} from "../shared/toast";

$(document).ready(() => {
  app.DOM.form.find('button.btn-copy-to-clipboard').click(async (e) => {
    e.preventDefault();
    const $target = $(e.currentTarget);
    const $icon = $target.find('i.fa')
    const $field = $target.prev('input');

    copyTextToClipboard($field.val())
    Toast.success('Copied to clipboard');
  });
});

async function copyTextToClipboard (text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  await navigator.clipboard.writeText(text);
}

function fallbackCopyTextToClipboard(text)
{
  var textarea = $('<textarea>').val(text).css({
    top: 0,
    left: 0,
    position: 'fixed',
  })[0];

  document.body.appendChild(textarea);
  textarea.focus()
  textarea.select()
  document.execCommand('copy')
  document.body.removeChild(textarea);
}